import * as React from "react";
import ImagePreview from "../_components/ImagePreview/ImagePreview";

const MapPreview = () => {
    return (
        <div className="map">
            <ImagePreview
                src={'https://s3.eu-west-1.amazonaws.com/download.live.xela.co/taste/tak/taste-london-2022-7.jpg'}
            />
        </div>
    );
};

export default MapPreview;
